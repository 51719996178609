import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ProcessPage = () => (
  <Layout>
    <SEO title="Process" keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} />
      <div>
        <h1>Electoral processes with Agora Voting</h1>
        <div>
          <h1>Pre-election</h1>
          <h2>Planning</h2>
          <p>Election dates are fixed and the electoral processes are complex, and require careful planning strategies. Our experience in thousands of elections allows us to develop contingency plans, minimize risks, delivering a robust voter experience.</p>
          <h2>Voter Education</h2>
          <p>Voter education is needed to let the electors exercise their right to vote. Well-informed voters are essential for a healthy and democratic electoral process and to achieve high participation.</p>
          <p>This requires transparency, which means not only an awesome website and down to earth documentation, but the strategic dissemination of information in multiple media formats.</p>
          <h2>Election configuration</h2>
          <p>The configuration of the election can be really complex. Election configuration includes the layout and presentation of the options, the questions, the tally methods, etc. Years of experience have allowed us to develop a powerful solution that lets us configure all the aspects of the election.</p>
          <h2>Personnel training</h2>
          <p>Effectively assisting the public during an election is one of the most important tasks to guarantee the right to vote. Correctly performing and orchestrating the internal processes of the election, such as executing the tally or managing the census and the issues that may arise, is not only essential but needs proper staff training.</p>
          <h2>Voter registration</h2>
          <p>Guaranteeing the right to vote requires that elegible voters can participate in the election. The registration of thousands or millions of electors is not only complex, but usually requires a sizable amount of human resources. Our voter registration solution grants legitimacy to the electoral process, keeping registration tasks simple and transparent.</p>
          <h1>Election-day</h1>
          <h2>On-site electronic voting</h2>
          <p>Electronic voting can be used on-site to improve the efficiency, security and accesibility of the electoral process. The usage of certified equipment, preserving the chain of custody, training the staff and providing support for on-site operations are some of the services included in our solution for on-site electronic voting.</p>
          <h2>Internet Voting</h2>
          <p>Our solution for remotely casting ballots through the Internet provides the highest security and privacy standards. Remote electronic voting is an efficient electoral solution for organizations, like the military or those living abroad, whose members are dispersed in a wide geographic area. It is also a good fit for smaller organizations lacking the resources to establish on-site polling stations.</p>
          <h3>Registration</h3>
          <h4>Offline voter registration</h4>
          <p>Election workers verify the identity of the electorate, checking the census data and the electors credentials.</p>
          <h4>Online voter registration</h4>
          <p>Alternatively, electors can register online. This allows to reach people living abroad and improves accesibility.</p>
          <h3>Authentication</h3>
          <p>Multiple authentication and signature mechanisms can be used, and sometimes more than one is used at once.</p>
          <h4>SMS one-time authentication codes, email, and more!</h4>
          <h4>Postal delivery of authentication codes</h4>
          <p>Credentials can safely be delivered via postal mail. The credentials can be a codes card, so that it can securely be reused in future elections.</p>
          <h4>Government-issued digital certificates or smart cards</h4>
          <p>Electronic ID cards, Smart cards and Government-issued digital certificates provide excellent security features in the use-cases where they can be used.</p>
          <h4>Hardware tokens</h4>
          <p>FIDO U2F tokens are standard, easy-to-use and provide both two-factor authentication and strong cryptographic security benefits.</p>
          <h3>Voting</h3>
          <p>Once authenticated, the voter enters the voting booth where he can cast his ballot securely.</p>
          <h4>Cast and seal the ballot</h4>
          <p>With client-side encryption, the ballot doesn’t go through any server, everything is done in the voter computer. The ballot is cryptographically sealed under multiple locks and keys at once, requring participation from each election authority.</p>
          <h4>Ballot locator generated</h4>
          <p>Before sending the vote and after being sealed, the locator – a snapshot of the ballot – is generated in the client. The ballot locator can be used later for ballot-related verifications.</p>
          <h4>Cast ballot is verifiable</h4>
          <p>Before sending and recording the ballot, the voter can mathematically verify that the ballot was correctly encoded and encrypted in a third-party computer.</p>
          <h4>Vote recording in digital ballot box</h4>
          <p>The voter sends the encrypted ballot to the digital ballot box. Because the ballot was encrypted in the user computer and decryption require the participation of the independent election authorities, not even election administrators can open it. This way, the privacy of the vote is cryptographically preserved. The server checks credentials and verifies the ballot. Once the ballot gets recorded, the voter will be able to check that the ballot was correctly recording using the ballot locator, at all times.</p>
          <h3>Tallying</h3>
          <p>After the elections ends, the ballots are compiled and election authorities execute a fully verifiable process that opens the ballots box and tallies the results, preserving the secrecy of the vote.</p>
          <h4>Digital votes compilation</h4>
          <p>The valid ballots are collected and extracted from the digital ballot box. These ballots will be sent to election authorities.</p>
          <h4>Re-encryption, mixing, shuffling</h4>
          <p>Each election authority executes an anonymization process called a mixnet:<br />1. Re-encrypt the ballots without opening them, making each of the ballots look completely different.<br />2. Shuffle the ballots changing their order, to make them unrecognizable.</p>
          <h4>Proof of content equivalence</h4>
          <p>The other authorities verify the re-encryption and shuffling proofs, so that they are sure that the resulting set of anonymized ballots are equivalent to the initial set of ballots, although in a different and undisclosed order.</p>
          <h4>Authorities joint decryption</h4>
          <p>Once the ballots have been independently anonymized by each authority, all the authorities have to jointly decrypted them to obtain the original intention of the voters, needed for doing the tally.</p>
          <h4>Tallying and result consolidation</h4>
          <p>The results are calculated applying the tally mechanisms to the decrypted ballots, and they are published online.</p>
          <h4>Proof of content decryption</h4>
          <p>The election authorities provide verifiable proofs of decryption of the ballots. Together with the results and the re-encryption and shuffle proofs, this allows to mathematically verify end to end the tally. All this information is published online in a single file that anyone can download and use to independently verify the whole process.</p>
          <h2>Support</h2>
          <p>Agora Voting offers a tailored solution providing support during the election to both voters and poll workers in a timely manner through email, phone, and an issue management system. After hundreds of thousands of votes counted with us, our solution effectively resolves the scale of the operations challenge that arises during electoral support.</p>
          <h2>Voter management</h2>
          <p>A streamlined and intuitive solution for voter management contributes to reduce the operational costs and the human errors produced during elections. Our electronic poll book not only provides secure and efficient voter data verification, but also produces valuable real-time data for election officials.</p>
          <h1>Post-election</h1>
          <h2>Auditing</h2>
          <p>Maintaining the legitimacy and credibility of an electronic election requires not only top-notch security software, hardware and protocols, but the auditing by experts in technology and processes to guarantee the integrity of the election.</p>
          <h2>Reporting</h2>
          <p>On election night everyone (candidates, the news, the public) is awaiting the results of the election. Accurately reporting these results in an understandable and timely manner can be crucial to the success of the whole operation. Our solution provides a professionally designed website to produce real-time, fast and secure election result updates.</p>
          <h2>Results consolidation</h2>
          <p>Opening the electronically sealed ballots, executing the tally process, preparing and consolidating the results in a secure, verifiable and timely manner requires skills and careful planning. Our solution for results consolidation is an effective way to guarantee the success of this final stage.</p>
          <h1>Innovation</h1>
          <h2>Participation platform</h2>
          <p>Sometimes a public or private organization needs to carry out electoral processes on a frequent basis. Our participation platform solution provides a secure, easy to use and cost-effective way of covering this need of both big and small organizations alike.</p>
          <h2>Electoral consulting</h2>
          <p>Our professionals’ years of experience in all kinds of elections is a valuable knowledge resource that you can leverage through our electoral consulting solution. This 360º solution covers advising goverments and private organizations in election planning and the development and implementation of tailored infraestructure and applications.</p>
        </div>
      </div>

  </Layout>
)

export default ProcessPage
